import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { ActivatedRoute } from '@angular/router';
import { AlertsType } from '@rappi/ui/alerts';
import { AccountsService } from '../../../../services/accounts.service';

import { Account, Config } from '../../../../definitions/accounts.models';
import { Permission } from '../../../../definitions/permissions.models';
import { AccountStatus, AccountStatusColor, AccountStatusMessage } from '../../../../definitions/permissions.enums';
import { AlertsService } from '../../../../..//services/alerts/alerts.service';

import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../store/states/app.state';
import { StoreName } from '../../../../../store/definitions/store.constants';
import { CountryState } from '../../../../../store/states/country.state';
import { COUNTRY_NAME } from '../../../../../definitions/countries.constants';
import { catchError, map, skip, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Observable, Subject } from 'rxjs';
import { ACTIONS } from '../../../../definitions/actions.enums';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit, OnDestroy {
  id: number;
  data$: Observable<Account>;
  country$: Observable<string>;
  VIEW = ACTIONS.view;
  showStoreAssigmentAccount = false;
  showMakerAssigmentAccount = false;
  planLabel: string;

  deleteAccount: (id: number) => Observable<Permission<unknown>>;

  readonly ACCOUNT_STATUS = AccountStatus;
  readonly ACCOUNT_STATUS_COLOR = AccountStatusColor;
  readonly ACCOUNT_STATUS_MESSAGE = AccountStatusMessage;

  destroySubject$: Subject<void> = new Subject();

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _accountsService: AccountsService,
    private readonly _alertsService: AlertsService,
    private readonly _location: Location,
    private readonly _store: Store<AppState>
  ) {
    this.id = Number(this._activatedRoute.snapshot.params.id);
    this.deleteAccount = (id: number) => this._accountsService.deleteAccount(id).pipe(tap(() => this._location.back()));

    this.country$ = this._store.pipe(
      select(StoreName.country),
      map(({ countrySelected }: CountryState): string => COUNTRY_NAME[countrySelected])
    );
    this.country$
      .pipe(
        skip(1),
        takeUntil(this.destroySubject$),
        tap(() => this._location.back())
      )
      .subscribe();
  }

  ngOnInit() {
    this.getConfig();

    this.data$ = this._accountsService.getAccount(this.id).pipe(
      map(({ data }: Permission<Account>): Account => data),
      catchError(({ error: { message } }: HttpErrorResponse) => {
        this._alertsService.openAlerts(message || 'There was an error trying to get account', AlertsType.error);
        return EMPTY;
      })
    );

    this.data$.subscribe(value=>{
      this.showMakerAssigmentAccount = (Object.keys(value.statement).length > 0);
      this.showStoreAssigmentAccount = (Object.keys(value.store_statement).length > 0);
    });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  getConfig(): void {
    this._accountsService
      .getConfig()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: Config) => (this.planLabel = res.account_create?.plan_label ?? ''));
  }
}
