<ng-container *ngIf="hasActiveModule">
  <div class="sidenav" [class.nav-collapsed]="collapsed">
    <ul class="side-menu" [class.collapsed]="collapsed">
      <div class="side-menu-header" [class.collapsed-header]="collapsed">
        <p class="user-name">Hi {{ firstName }}</p>
        <p class="company-title" [appCustomTooltip]="email" tooltipPosition="bottom">{{ email }}</p>
      </div>
      <ng-container *ngFor="let item of items; let indexItem = index">
        <ul class="side-menu-list" *ngIf="item.active" routerLinkActive="link-active">
          <h2 class="side-menu-title" *ngIf="someActiveModule(item.modules) && item.product as heading">
            {{ heading | translate }}
          </h2>
          <ng-container *ngFor="let module of item.modules; let indexModule = index">
            <ng-container *ngIf="module.children; else noChildren">
              <li class="side-menu-item" *ngIf="module.active" routerLinkActive="active">
                <span
                  class="side-menu-link"
                  [class.collapsed-link]="collapsed"
                  (click)="displaySubmenu(indexItem, indexModule)"
                >
                  <i [class]="module.icon" aria-hidden="true"></i>
                  <span class="side-menu-link-text">{{ module.name | translate }}</span>
                  <i class="one-icon-chevron-left" [class.one-submenu-open]="module.openMenu" aria-hidden="true"></i>
                </span>
                <ul class="side-submenu-item" [class.show-submenu]="module.openMenu">
                  <ng-container *ngFor="let child of module.children">
                    <li class="side-menu-item" *ngIf="child.active">
                      <a
                        class="side-menu-link side-submenu-link"
                        [class.collapsed-link]="collapsed"
                        [routerLink]="['/' + module.url + '/' + child.url]"
                        routerLinkActive="submenu-active"
                        (click)="
                          addNewModuleVisited(child);
                          emitAmplitudeEventMenuDesktop('/' + module.url + '/' + child.url, _router.url)
                        "
                      >
                        <span class="side-menu-link-text">{{ child.name | translate }}</span>
                        <span
                          *ngIf="child.isNew && !urlNewModulesVisited.includes(child.url)"
                          class="side-menu-tag-new-feature"
                        ></span>
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
            <ng-template #noChildren>
              <li class="side-menu-item" *ngIf="module.active">
                <a
                  class="side-menu-link"
                  [class.collapsed-link]="collapsed"
                  [routerLink]="['/' + module.url]"
                  routerLinkActive="active"
                  (click)="addNewModuleVisited(module); emitAmplitudeEventMenuDesktop('/' + module.url, _router.url)"
                >
                  <i [class]="module.icon" aria-hidden="true"></i>
                  <span class="side-menu-link-text">{{ module.name | translate }}</span>
                  <span
                    *ngIf="module.isNew && !urlNewModulesVisited.includes(module.url)"
                    class="side-menu-tag-new-feature"
                  ></span>
                </a>
              </li>
            </ng-template>
          </ng-container>
        </ul>
      </ng-container>
    </ul>
    <div class="learning-content" [class.collapsed-learning]="collapsed">
      <div class="learning-hub-card" (click)="navigateLearning()">
        <div class="icon">
          <img src="assets/images/learning-hub.png" alt="" />
        </div>
        <h2 class="title">Learning Hub</h2>
        <p class="description">How to easily use the platform</p>
      </div>
    </div>
  </div>
</ng-container>
