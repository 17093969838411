import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { AccountsRoutingModule } from './accounts.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { FormErrorsDirectiveModule } from '@rappi/common';
import { InputModule } from '@rappi/ui/input';
import { SelectModule } from '@rappi/ui/select';
import { RangeDatepickerModule } from '@rappi/ui/range-datepicker';
import { DatepickerModule } from '@rappi/ui/datepicker';
import { FormGroupModule } from '../../components/form-group/form-group.module';
import { ViewFormFieldModule } from '../../components/view-form-field/view-form-field.module';
import { DynamicTableModule } from '@rappi/ui/dynamic-table';
import { CardModule } from '@rappi/ui/card';
import { CustomTooltipModule } from '@rappi/ui/custom-tooltip';

import { TableComponent } from './components/table/table.component';
import { ManagerComponent } from './components/manager/manager.component';
import { ViewComponent } from './components/view/view.component';
import { MakerListComponent } from './components/maker-list/maker-list.component';
import { LicensesListDialogComponent } from './components/licenses-list-dialog/licenses-list-dialog.component';
import { ActionsModule } from '../../components/actions/actions.module';
import { MatDialogModule } from '@angular/material/dialog';
import { RolSelectorComponent } from './components/rol-selector/rol-selector.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    TableComponent,
    ManagerComponent,
    ViewComponent,
    MakerListComponent,
    LicensesListDialogComponent,
    RolSelectorComponent
  ],
  imports: [
    CommonModule,
    AccountsRoutingModule,
    ReactiveFormsModule,
    FormErrorsDirectiveModule,
    InputModule,
    SelectModule,
    RangeDatepickerModule,
    DynamicTableModule,
    CardModule,
    CustomTooltipModule,
    FormGroupModule,
    ViewFormFieldModule,
    ActionsModule,
    MatDialogModule,
    DatepickerModule,
    MatProgressBarModule
  ]
})
export class AccountsModule {}
