import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GenericDict } from '@rappi/common';
import {
  DynamicTableCellType,
  DynamicTableComponent,
  DynamicTableData,
  DynamicTableRow,
  LazyTableDataSource
} from '@rappi/ui/dynamic-table';
import { Observable, Subject } from 'rxjs';
import { map, skip, takeUntil, tap } from 'rxjs/operators';
import { TableMenuTriggerComponent } from '../../../../components/table-menu-trigger/table-menu-trigger.component';
import { License } from '../../../../definitions/licenses.models';
import { Paginate, Permission } from '../../../../definitions/permissions.models';
import { LicensesService } from '../../../../services/licenses.service';
import { AppState } from '../../../../../store/states/app.state';
import { StoreName } from '../../../../../store/definitions/store.constants';
import { UserType } from '../../../../../permissions/definitions/permissions.enums';
import { UserState } from '../../../../../store/states/user.state';
import { ConfirmAccountComponent } from '../../../../../core/landing/confirm-account/confirm-account.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from '../../../../services/util.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy {
  @ViewChild('table') table: DynamicTableComponent;

  licensesTableData: DynamicTableData;

  destroySubject$ = new Subject<void>();
  private readonly PAGE_SIZE = 5;
  private readonly INIT_PAGE = 1;

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _licensesService: LicensesService,
    private readonly _utilService: UtilService,
    private readonly _dialog: MatDialog
  ) {}

  ngOnInit() {
    this.licensesTableData = this.initTableConfig();

    this._store.pipe(select(StoreName.country), skip(1), takeUntil(this.destroySubject$)).subscribe(() => {
      this.table.resetTable();
    });
  }

  initTableConfig(): DynamicTableData {
    const dataSource = new LazyTableDataSource<DynamicTableRow>(
      this.getLicensesList.bind(this),
      this.PAGE_SIZE,
      '',
      this.INIT_PAGE
    );

    return {
      title: {
        value: 'LICENSES MANAGEMENT'
      },
      dataSource,
      lazyLoaded: true,
      pagination: true,
      paginatorOpts: { pageSize: this.PAGE_SIZE },
      columns: ['account', 'email_address', 'last_login', 'actions'],
      cellsConfig: {
        account: {
          type: DynamicTableCellType.text
        },
        email_address: {
          type: DynamicTableCellType.text,
          title: 'Email Address'
        },
        last_login: {
          type: DynamicTableCellType.text,
          title: 'Last Login'
        },
        actions: {
          type: DynamicTableCellType.customComponent,
          properties: {
            component: TableMenuTriggerComponent,
            inputs: ['id', 'type', 'service', 'isAdmin$', 'confirmAccount', 'sendEmail', 'resetPassword'],
            outputs: []
          },
          hideTitle: true
        }
      }
    };
  }

  getLicensesList(page = this.INIT_PAGE, size = this.PAGE_SIZE, filterName: string): Observable<GenericDict> {
    return this._licensesService.getLicenses(page, size, filterName).pipe(
      map(({ data }: Permission<Paginate<License>>) => {
        const offset = this.licensesTableData.dataSource.data.length;

        if (!offset && data.total_record && this.licensesTableData.paginator) {
          this.licensesTableData.paginator.length = data.total_record;
        }

        return (data.records || []).map((elem: License) => ({
          account: {
            value: elem.account
          },
          email_address: {
            value: elem.email
          },
          last_login: {
            value: elem.last_login
          },
          actions: {
            inputs: {
              id: elem.id,
              type: 'License',
              service: (i: number) => this._licensesService.deleteLicense(i).pipe(tap(() => this.table.resetTable())),
              isAdmin$: this._store.pipe(
                select(StoreName.user),
                map(({ user_type }: UserState): boolean => user_type === UserType.admin)
              ),
              confirmAccount: this.confirmAccount.bind(this, elem.email),
              sendEmail: this.sendEmail.bind(this, elem.email),
              resetPassword: this.resetPassword.bind(this, elem.email)
            }
          }
        }));
      })
    );
  }

  confirmAccount(email: string) {
    this._dialog.open(ConfirmAccountComponent, {
      disableClose: true,
      data: { email }
    });
  }

  sendEmail(email: string) {
    this._utilService.sendEmail(email);
  }

  resetPassword(email: string): void {
    this._utilService.resetPassword(email);
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
