import { Injectable } from '@angular/core';

import { EncryptService } from '../encrypt/encrypt.service';

import { STORAGE_KEY } from '../../definitions/app.constants';
import { Country } from '../../models/shared/shared.model';
import { COUNTRY_NAME } from '../../definitions/countries.constants';
import { Action, UserResponseAuth } from '../../models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private readonly _encryptService: EncryptService) { }

  getCountry(): Country {
    const code = this._encryptService.decryptText(localStorage.getItem(STORAGE_KEY.country), STORAGE_KEY.country);
    return { name: COUNTRY_NAME[code], code };
  }

  getCountries(): Country[] {
    const countries: Country[] = this._encryptService.decryptObject(
      localStorage.getItem(STORAGE_KEY.countries),
      STORAGE_KEY.countries
    );
    return countries.map((elem: Country) => ({
      name: COUNTRY_NAME[elem.code],
      code: elem.code,
      platform_outage: elem.platform_outage
    }));
  }

  getUserAction(): Action {
    return this._encryptService.decryptObject(
      localStorage.getItem(STORAGE_KEY.actions),
      STORAGE_KEY.actions
    ) as Action;
  }

  saveDataOnStorage(data: UserResponseAuth) {
    this.clearStorageSession();

    localStorage.setItem(STORAGE_KEY.token, data.token.token);
    localStorage.setItem(STORAGE_KEY.actions, this._encryptService.encryptObject(data.actions, STORAGE_KEY.actions));
    localStorage.setItem(
      STORAGE_KEY.countries,
      this._encryptService.encryptObject(data.countries, STORAGE_KEY.countries)
    );
    if (!localStorage.getItem(STORAGE_KEY.country)?.length) {
      localStorage.setItem(
        STORAGE_KEY.country,
        this._encryptService.encryptText(data.actions.country_code, STORAGE_KEY.country)
      );
    }
    localStorage.setItem(STORAGE_KEY.email, data.user.email);
    localStorage.setItem(STORAGE_KEY.id, data.user.id);
    localStorage.setItem(STORAGE_KEY.fullName, `${data.user.first_name} ${data.user.last_name}`);
  }

  setStorageCountry(countryCode: string): void {
    localStorage.setItem(
      STORAGE_KEY.country,
      this._encryptService.encryptText(countryCode, STORAGE_KEY.country)
    );
  }

  clearStorageSession(): void {
    localStorage.removeItem(STORAGE_KEY.token);
    localStorage.removeItem(STORAGE_KEY.actions);
    localStorage.removeItem(STORAGE_KEY.countries);
    localStorage.removeItem(STORAGE_KEY.email);
    localStorage.removeItem(STORAGE_KEY.id);
    localStorage.removeItem(STORAGE_KEY.fullName);
    localStorage.removeItem(STORAGE_KEY.makerSelected);
    localStorage.removeItem(STORAGE_KEY.akitaStores);
  }
}
