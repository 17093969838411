import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsMfeComponent } from '../reports-mfe/reports-mfe.component';
import { environment } from '../../../environments/environment';

const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: environment.modules.campaignReports,
    },
    children: [
      {
        path: '',
        component: ReportsMfeComponent,
        data: {
          isMfe: true,
          module: environment.modules.campaignReports,
          submodule: 'reports',
          hideBreadcrumbs: true,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: ReportsMfeComponent,
            data: {
              isMfe: true,
              module: environment.modules.campaignReports,
              submodule: 'reports',
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignReportsMfeRoutingModule { }
