import { Component, forwardRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { DEFAULT_SELECT_CONFIG, SelectComponent, SelectConfig, SelectSize } from '@rappi/ui/select';
import { Entity } from './../../../../definitions/permissions.models';
import { LazyScrollableDataSource } from '@rappi/common';
import { AccountsService } from './../../../../services/accounts.service';
import { Observable } from 'rxjs';
import { Config } from './../../../../definitions/accounts.models';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-rol-selector',
  templateUrl: './rol-selector.component.html',
  styleUrls: ['./rol-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RolSelectorComponent),
      multi: true
    }
  ]
})
export class RolSelectorComponent implements ControlValueAccessor {
  @ViewChild('rolSelectNgx', { static: true }) rolSelectNgx: SelectComponent<Entity | Entity[]>;

  @Input() label = 'Role';
  @Input() multiple = false;
  @Input() disabled: boolean;
  @Input() size: SelectSize = 'default';

  @Input() set roleCompanyId(role: string) {
    if (role !== '') {
      const data = { id: role, name: role };
      this.rolSelectNgx.setSelection(data);
      this.setDisabledState(true);
    }
  }

  dataSource: LazyScrollableDataSource<Entity[]> = new LazyScrollableDataSource<Entity[]>(
    this.fetchRolesList.bind(this),
    10
  );

  private selectConfig: SelectConfig = DEFAULT_SELECT_CONFIG;

  @Input() set config(value: SelectConfig) {
    this.selectConfig = { ...DEFAULT_SELECT_CONFIG, ...value };
  }

  get config(): SelectConfig {
    return this.selectConfig;
  }

  constructor(private readonly _service: AccountsService) {}

  onChange = (v) => {};
  onTouched = () => {};

  fetchRolesList(): Observable<Entity[]> {
    return this._service.getConfig().pipe(
      map((res: Config) =>
        res.account_roles.map((role) => ({
          id: role.id,
          name: role.label
        }))
      )
    );
  }

  selectionChanged(event): void {
    this.onChange(event);
    this.onTouched();
  }

  writeValue(value: Entity | Entity[]): void {
    if (value) {
      this.rolSelectNgx.setSelection(value);
      this.updateSelection(Array.isArray(value) ? [...value] : [value]);
    } else {
      this.rolSelectNgx.clearSelection();
      this.updateSelection();
    }
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.rolSelectNgx.setDisabledState(isDisabled);
  }

  errorsFor(): ValidationErrors {
    const control = this.rolSelectNgx.control;
    return (control.dirty || control.touched) && control.errors;
  }

  private updateSelection(selection?: Entity[]): void {
    this.rolSelectNgx.selection.clear();
    if (selection) {
      this.rolSelectNgx.selection.select(...selection);
    }
  }
}
