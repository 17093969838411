<one-select
  #rolSelectNgx
  [dataSource]="dataSource"
  [label]="label"
  (selectionChanged)="selectionChanged($event);"
  [appFormErrors]="errorsFor()"
  [disabled]="disabled"
  [config]="config"
  [multiple]="multiple"
  [size]="size"
>
</one-select>
