<app-actions [id]="id" type="Account" [action]="action" [service]="deleteAccount"></app-actions>
<form [formGroup]="form" (submit)="onSubmit(form)">
  <app-form-group icon="one-icon-user-tooltip" title="General Information">
    <div>
      <one-input label="Account ID" [value]="id" disabled="true"></one-input>
    </div>
    <div>
      <one-input label="Account Title" formControlName="title" appFormErrors></one-input>
    </div>
    <div>
      <one-input label="Email Address" formControlName="email" appFormErrors></one-input>
    </div>
    <div>
      <one-input
        [class.error-input]="errorCompanyId.flagError"
        label="Company ID"
        (blur)="getChangesCompanyId()"
        [disabled]="isRappiDomain || defaultDisabled"
        formControlName="company_id"
        appFormErrors
      ></one-input>
      <mat-progress-bar *ngIf="loadingCompany" class="rp-loading-bar company" mode="indeterminate"></mat-progress-bar>
      <span *ngIf="errorCompanyId.flagError" class="error-message">{{ errorCompanyId.message }}</span>
    </div>
    <div>
      <app-rol-selector
        formControlName="rol"
        [roleCompanyId]="role"
        [disabled]="!isRappiDomain || defaultDisabled"
        appFormErrors
      ></app-rol-selector>
    </div>
  </app-form-group>

  <div>
    <app-form-group icon="one-icon-diagonal-tag" title="Maker - Account Assignment">
      <div>
        <one-select
          #countriesSelect
          [dataSource]="countriesDataSource | async"
          label="Country"
          [disabled]="true"
          appFormErrors
        ></one-select>
      </div>
      <div>
        <one-select
          #citySelect
          [multiple]="true"
          [dataSource]="citiesDataSource"
          label="City"
          [config]="SELECT_CONFIG"
          (selectionChanged)="setStatementValue($event, 'city'); evaluationFieldsRequiredMakerAndStore()"
          appFormErrors
        ></one-select>
      </div>
      <div>
        <one-select
          #verticalSelect
          [multiple]="true"
          [dataSource]="verticalsDataSource"
          [label]="MASTER_DATA_ENTITY.vertical"
          [config]="SELECT_CONFIG"
          (selectionChanged)="setStatementValue($event, 'vertical'); evaluationFieldsRequiredMakerAndStore()"
          appFormErrors
        ></one-select>
      </div>
      <div>
        <one-select
          #makerSelect
          [multiple]="true"
          [dataSource]="makersDataSource"
          [label]="MASTER_DATA_ENTITY.maker"
          [config]="SELECT_CONFIG"
          (selectionChanged)="setStatementValue($event, 'maker', 'trademarks'); evaluationFieldsRequiredMakerAndStore()"
          appFormErrors
        ></one-select>
      </div>
      <div>
        <one-select
          #trademarkSelect
          [multiple]="true"
          [dataSource]="trademarksDataSource"
          [label]="MASTER_DATA_ENTITY.trademark"
          [config]="SELECT_CONFIG"
          (selectionChanged)="
            setStatementValue($event, 'trademark', 'subcategories'); evaluationFieldsRequiredMakerAndStore()
          "
          appFormErrors
          [disabled]="makerSelect.allSelected ? false : !makerSelect?.control?.value?.length"
        ></one-select>
      </div>
      <div>
        <one-select
          #subcategorySelect
          [multiple]="true"
          [dataSource]="subcategoriesDataSource"
          [label]="MASTER_DATA_ENTITY.subcategory"
          [config]="SELECT_CONFIG"
          (selectionChanged)="setStatementValue($event, 'subcategory'); evaluationFieldsRequiredMakerAndStore()"
          appFormErrors
          [disabled]="trademarkSelect.allSelected ? false : !trademarkSelect?.control?.value?.length"
        ></one-select>
      </div>
    </app-form-group>

    <app-form-group icon="one-icon-diagonal-tag" title="Store - Account Assignment">
      <div>
        <one-select
          #countriesSelectStore
          [dataSource]="countriesDataSource | async"
          label="Country"
          [disabled]="true"
        ></one-select>
      </div>
      <div>
        <one-select
          #verticalStoreSelect
          [multiple]="true"
          [dataSource]="verticalsStoreDataSource"
          [label]="MASTER_DATA_ENTITY.vertical"
          [config]="SELECT_CONFIG"
          (selectionChanged)="
            setStatementValue($event, 'verticalStore');
            storeDataSource.setFilterValue(null);
            cleanStores();
            evaluationFieldsRequiredMakerAndStore()
          "
          appFormErrors
        ></one-select>
      </div>
      <div>
        <one-select
          #storeSelect
          [disabled]="verticalStoreSelect.allSelected ? false : !verticalStoreSelect?.control?.value?.length"
          [multiple]="true"
          [dataSource]="storeDataSource"
          label="Store brand"
          [config]="SELECT_CONFIG"
          (selectionChanged)="
            statement.get('store').setValue(storeSelect.allSelected ? '-1' : $event);
            evaluationFieldsRequiredMakerAndStore()
          "
        ></one-select>
      </div>
    </app-form-group>
  </div>

  <app-form-group icon="one-icon-settings" title="Plan Details" [subtitle]="planLabel">
    <button
      *ngIf="action === ACTIONS.edit"
      class="one-icon-plus-circle rp-button rp-success"
      (click)="addNewPO(); getChangesNewPO(plans.length - 1)"
      [disabled]="plans.length > plansNumber"
      id="actions"
    >
      Add New Plan
    </button>

    <ng-container formArrayName="plans">
      <ng-container *ngFor="let plan of plans?.controls; index as i" [formGroupName]="i">
        <div
          *ngIf="
            action === ACTIONS.edit &&
            plan.value.expiration_status &&
            plan.value.expiration_status !== ACCOUNT_STATUS.active
          "
          class="one-icon-exclamation-circle alert"
          [style.--color]="ACCOUNT_STATUS_COLOR[plan.value.expiration_status]"
        >
          <span>
            {{ ACCOUNT_STATUS_MESSAGE[plan.value.expiration_status] }}
          </span>
        </div>

        <div>
          <one-input
            [disabled]="plan.value.expiration_status === ACCOUNT_STATUS.expired"
            label="KAM Email Address"
            formControlName="kam_email"
            appFormErrors
          ></one-input>
        </div>
        <div>
          <one-select
            #tierTypeSelect
            [dataSource]="tierTypeDataSource"
            [config]="{ panelClass: '', search: true }"
            label="Tier Title"
            (selectionChanged)="setPlanValue($event, 'tier_id', i, 'tier')"
            [disabled]="plan.value.expiration_status === ACCOUNT_STATUS.expired || !email.valid"
            appFormErrors
          ></one-select>
        </div>
          <div>
            <one-datepicker
              [min]="toDay"
              [max]="plans.controls[i].get('ends_at').value ? plans.controls[i].get('ends_at').value : null"
              formControlName="start_at"
              label="Start Date"
            ></one-datepicker>
          </div>
          <div>
            <one-datepicker
              [min]="plans.controls[i].get('start_at').value > toDay ? plans.controls[i].get('start_at').value : toDay"
              formControlName="ends_at"
              label="End Date"
            ></one-datepicker>
          </div>
        <hr *ngIf="i !== plans.controls.length - 1" class="span-2" />
      </ng-container>
    </ng-container>
  </app-form-group>
  <div class="form-actions">
    <button class="rp-button rp-filled rp-raisable" (click)="goTo(viewUrl || tableUrl)" type="button">Cancel</button>
    <button class="rp-button rp-filled rp-raisable rp-success" [disabled]="form.invalid || errorCompanyId.flagError">
      {{ action | titlecase }} Account
    </button>
  </div>
</form>
