import { UserTier } from '../../store/definitions/user.enums';
import { UserType } from '../../permissions/definitions/permissions.enums';
import { Country } from '../shared/shared.model';

export interface ControlPanelAlert {
  id?: string;
  countries: string[];
  title: string;
  message: string;
  is_active?: boolean;
  creator?: string;
  modifier?: string;
  updated_at?: string;
}

export interface LoginResponseAuth {
  data: UserResponseAuth;
  message: string;
}

export interface UserResponseAuth {
  token: Token;
  user: User;
  actions: Action;
  countries: Array<Country>;
  platform_outage: boolean;
  countries_alert: ControlPanelAlert;
}

export interface UserSuccessActionResponse {
  message: string;
}

export interface Token {
  type: string;
  token: string;
  refreshToken?: string;
}

export interface User {
  email?: string;
  first_name: string;
  last_name: string;
  country_code?: string;
  phone?: string;
  actions?: Action;
  id: string;
}

export interface Action {
  account_name: string;
  account_email?: string;
  company_code: string[];
  company_name: string[];
  country_order: number;
  modules: Module[];
  country_code: string;
  tier_name: UserTier;
  user_type: UserType;
  kam_email: string;
}

export interface Module {
  name?: string;
  slug: string;
}

export interface ModuleAux {
  name: string;
}

export interface UserAuth {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  country_id: number;
  company: number;
  companyName: string;
  reason: string;
  phone: string;
  password?: string;
  created_at: string;
  update_at: string;
  source?: string;
  product?: string;
}

export interface GoogleStateCode {
  state: string;
  code: string;
}

export const CONST_STORAGE = {
  ID: 'id',
  EMAIL: 'email',
  CC: 'cc',
  COUNTRIES: 'countries',
  ISA: 'isa',
  accountName: 'account_name',
  tierName: 'tier_name',
  MODULE: 'module'
};

export interface PasswordValidatorInterface {
  regex: string;
  regexErrorMessage: string;
  matchErrorMessage: string;
}

export const PasswordValidatorConst: PasswordValidatorInterface = {
  regex: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#~$%^&*()+._?]).{8,}$',
  regexErrorMessage: ` Passwords must be at least 8 character long and contains at
  least one upper case letter, one lower case letter,
  one number and any of following characters !@#~$%^&*()+._?
  `,
  matchErrorMessage: 'The password does not match'
};
